<template>
  <div
    key="subCheck"
    v-click-outside="handleClose"
    class="flex flex-col h-full max-h-screen overflow-y-auto rounded-xl"
  >
    <!-- Loading State -->
    <div
      v-if="isLoading"
      class="my-40 flex justify-center flex-grow"
    >
      <BrightbidLoader />
    </div>
    <!-- Error State -->
    <div
      v-if="!isLoading && checkError"
      class="my-40 flex justify-center flex-grow"
    >
      <div class="flex flex-col items-center text-center mt-20">
        <img
          src="/content/images/brightbid/bidbot-broken.svg"
          class="mb-6 w-25"
        />
        <p class="h3">Could not open audit check</p>
        <p>Please try to refresh the page and try again.</p>
      </div>
    </div>
    <div
      v-if="!isLoading && !checkError"
      class="flex flex-row justify-between pt-7 ml-10 mr-20"
    >
      <div class="flex flex-row gap-2">
        <Pill :color="'lightGray'">
          <template #text>
            <p class="capitalize">{{ formatCategory(subCheck.category) }}</p>
          </template>
        </Pill>
        <Pill
          v-if="subCheck.status !== 'skipped' && subCheck.severity !== 'passed'"
          :color="severityItems.pillColor"
        >
          <template #text>
            <div class="flex items-center">
              <component
                :is="severityItems.severityIcon"
                :size="16"
                :class="severityItems.severityIconStyle"
                class="mx-auto"
              />
              <p
                class="font-bold mx-1"
                :class="severityItems.failedSubChecksStyle"
              >
                {{ subCheck.issues_count }}
              </p>
              <p class="font-medium pl-1 capitalize">{{ severityItems.severityText }}</p>
            </div>
          </template>
        </Pill>
      </div>
      <p class="font-medium text-bb-text-secondary">Audited on: {{ formattedCreatedAtDate }}</p>
    </div>

    <!-- Title -->
    <div
      v-if="!isLoading && !checkError"
      class="flex flex-col justify-items-start mx-10 border-b py-5"
    >
      <h4 class="h4">{{ subCheck.title }}</h4>
      <div
        v-if="brandTerms.length > 0"
        class="flex flex-wrap gap-2 mt-2 mr-auto"
      >
        <div
          v-for="term in brandTerms"
          :key="term"
          class="gap-2 bg-bb-blue-pale-secondary font-medium rounded border-2 border-bb-blue-tertiary text-bb-blue flex flex-row px-2 items-center"
        >
          <ShieldCheckOutline
            :size="16"
            class="p-px rounded-custom bg-bb-blue-pale"
          />
          <p class="capitalize">{{ term }}</p>
        </div>
        <ic-cog
          :size="16"
          class="text-bb-brand-purple my-auto cursor-pointer"
          @click="$router.push({ name: 'site:settings:site', query: { tab: 'SiteAndBusinessForm' } })"
        ></ic-cog>
      </div>
    </div>
    <!-- Skipped content -->
    <div
      v-if="subCheck && subCheck.status === 'skipped' && !isLoading && !checkError"
      class="flex flex-col flex-grow overflow-hidden"
    >
      <!-- Passed State -->
      <div class="mx-10 flex-grow flex flex-col justify-center items-center">
        <div class="bg-neutral-0 rounded-lg my-3 flex flex-col justify-center items-center">
          <img
            src="/content/images/brightbid/skipped-robot.svg"
            alt=""
            class="w-32 pt-5"
          />
          <p
            class="p1 text-center"
            v-html="formattedSubtitle"
          ></p>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div
      v-else-if="subCheck && subCheck.severity !== 'passed' && !isLoading && !checkError"
      class="flex flex-col overflow-y-scroll h-full"
    >
      <div
        v-if="subCheck.severity !== 'passed'"
        key="notRemovedNotRestored"
        class="mx-10 flex flex-col"
      >
        <div class="overflow-y-auto mt-7 flex-grow flex flex-col">
          <!-- Subtitle -->
          <div>
            <p
              class="p1"
              v-html="formattedSubtitle"
            ></p>
          </div>

          <!-- Recommendation -->
          <div
            v-if="subCheck.recommendation"
            class="w-full bg-purple-0 rounded-2xl px-3 sm:px-6 py-3 sm:py-4 mt-7"
          >
            <div class="flex flex-row items-start">
              <div class="bg-white rounded-lg p-2 flex shadow-drop-12 mr-3 sm:mr-5">
                <IcLightBulbOnOutline class="text-bb-brand-purple" />
              </div>
              <div class="flex flex-col gap-y-1">
                <div v-html="formattedRecommendation"></div>

                <a
                  class="font-bold text-bb-brand-purple flex flex-row items-center max-w-40"
                  target="_blank"
                  href="https://ads.google.com/aw/overview"
                >
                  <span>Go to Google Ads</span>
                  <IcChevron
                    direction="right"
                    class="ml-1 w-4 h-4"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- Table Container -->
          <div class="pt-2 pb-4 flex-grow flex flex-col gap-2 overflow-hidden">
            <p
              v-if="subCheck.date_range"
              class="text-bb-text-secondary font-medium p3 text-right"
            >
              {{ subCheck.date_range }}
            </p>
            <SimpleTable
              :table-data="subCheck.result"
              class="flex-grow"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Passed content -->
    <div
      v-else-if="subCheck && subCheck.severity === 'passed' && !isLoading && !checkError"
      class="flex flex-col flex-grow overflow-hidden"
    >
      <!-- Passed State -->
      <div class="mx-10 flex-grow flex flex-col justify-center items-center">
        <div class="bg-neutral-0 rounded-lg mt-3 flex flex-col justify-center items-center">
          <img
            src="/content/images/brightbid/robot-check.svg"
            alt=""
            class="w-32 pt-5"
          />
          <p
            class="py-4 xs:px-4 xs:text-center"
            v-html="formattedSubtitle"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pill from '@/components/input/Pill.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import IcClock from '@/components/icon/brightbid/IcClock.vue'
import IcAlertOutline from 'vue-material-design-icons/AlertOutline'
import IcMinusCircleOutline from 'vue-material-design-icons/MinusCircleOutline'
import IcLightBulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcReplay from 'vue-material-design-icons/Replay'
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcLightBulbOnOutline from 'vue-material-design-icons/LightbulbOnOutline'
import SimpleTable from '@/components/table/SimpleTable.vue'
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '../loader/BrightbidLoader.vue'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import ShieldCheckOutline from 'vue-material-design-icons/ShieldCheckOutline.vue'
import IcCog from 'vue-material-design-icons/CogOutline'
import DOMPurify from 'dompurify'

export default {
  name: 'AuditReportModal',
  components: {
    Pill,
    MergeButtonRound,
    IcClock,
    IcAlertOutline,
    IcChevron,
    IcAlertCircle,
    IcMinusCircleOutline,
    IcCheckCircleOutline,
    IcLightBulbOutline,
    IcLightBulbOnOutline,
    dayjs,
    IcReplay,
    SimpleTable,
    Toast,
    BrightbidLoader,
    ShieldCheckOutline,
    IcCog,
  },
  props: {
    identification: {
      type: Number,
      required: true,
    },
    checkId: {
      type: String,
      required: true,
    },
    auditId: {
      type: String,
      required: true,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    brandTerms: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      isRemoved: false,
      isRestored: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('auditCheck', { subCheck: 'getCheckDetails', checkError: 'getError' }),
    customerId() {
      // Use the route parameter 'id' as the customerId
      return this.$route.params.id
    },
    severityItems() {
      const severityMap = {
        warning: {
          pillColor: 'warningYellow',
          failedSubChecksStyle: 'text-bb-warning',
          severityIcon: 'IcAlertCircle',
          severityIconStyle: 'text-bb-warning',
          severityText: 'Warnings',
        },
        urgent: {
          pillColor: 'errorRed',
          failedSubChecksStyle: 'text-bb-red-error',
          severityIcon: 'IcAlertOutline',
          severityIconStyle: 'text-bb-red-error',
          severityText: 'Urgent Issues',
        },
        optimization: {
          pillColor: 'purple',
          failedSubChecksStyle: 'text-bb-brand-purple',
          severityIcon: 'IcLightBulbOutline',
          severityIconStyle: 'text-bb-brand-purple',
          severityText: 'Optimizations',
        },
        undefined: {
          pillColor: 'gray',
          failedSubChecksStyle: 'text-bb-grey',
          severityIcon: 'IcAlertCircle',
          severityIconStyle: 'text-bb-grey',
          severityText: 'Undefined',
        },
      }
      return severityMap[this.subCheck.severity] || severityMap.undefined
    },
    formattedCreatedAtDate() {
      return this.subCheck ? dayjs(this.subCheck.created_at).format('DD/MM/YYYY') : ''
    },
    formattedRecommendation() {
      if (this.subCheck && this.subCheck.recommendation) {
        const recommendation = this.subCheck.recommendation

        // Sanitize the content and preserve existing anchor tags
        const sanitizedContent = DOMPurify.sanitize(recommendation, {
          ADD_ATTR: ['target', 'rel'], // Make sure target and rel attributes are allowed for links
          FORBID_TAGS: ['script'], // Prevent malicious script tags
        })

        // Replace newlines with <br> for proper line breaks
        const contentWithLineBreaks = sanitizedContent.replace(/\n/g, '<br>')

        return contentWithLineBreaks
      }
      return ''
    },
    formattedSubtitle() {
      return this.subCheck && this.subCheck.subtitle ? this.subCheck.subtitle.replace(/\n/g, '<br>') : ''
    },
  },
  mounted() {
    this.fetchAuditCheckData()
  },
  methods: {
    ...mapActions('auditCheck', ['fetchCheckDetails', 'fetchExternalCheckDetails']),

    handleClose() {
      this.$emit('close')
    },
    async fetchAuditCheckData() {
      this.isLoading = true
      // Choose the appropriate action based on `isExternal`
      if (this.isExternal || this.customerId) {
        await this.fetchExternalCheckDetails({
          customerId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      } else {
        await this.fetchCheckDetails({
          siteId: this.identification,
          reportId: this.auditId,
          checkId: this.checkId,
        })
      }
      this.isLoading = false
    },
    formatCategory(category) {
      switch (category) {
        case 'ads':
          return 'Ads & Assets'
        default:
          return category
      }
    },
  },
}
</script>

<style scoped>
.modal-container {
  z-index: 9000;
}
</style>
