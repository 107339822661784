<template>
  <div class="flex flex-col h-full">
    <div class="flex-grow overflow-x-auto overflow-y-auto border border-gray-200 rounded-xl">
      <table class="min-w-full table-fixed bg-white">
        <thead class="border-b sticky top-0 z-50 bg-white">
          <tr>
            <th
              v-for="(header, index) in displayedHeaders"
              :key="`main-header-${index}`"
              class="h5 py-3 border-b border-gray-200 capitalize cursor-pointer hover:bg-neutral-0 whitespace-no-wrap"
              :class="{
                'w-20': displayedHeaders.length > 5,
                'w-60 ': displayedHeaders.length < 4,
                'pl-10': hasSubRows,
                'pl-4': !hasSubRows,
              }"
              @click="sortBy(header.key)"
            >
              <div class="flex items-center">
                {{ formatHeader(header.value) }}
                <DropdownArrow
                  class="ml-2"
                  :direction="getSortDirection(header.key)"
                />
              </div>
              <div
                v-if="hasSubRows && index === 0"
                class="flex flex-row gap-x-1"
              >
                <IcArrowDownRight class="w-4 h-4 mt-1 text-bb-neutral-gray" />
                <p class="p2 pt-1">{{ formatHeader(allSubRowHeaders[0].value) }}</p>
              </div>
              <!-- EXCEPTION FOR LAST SEEN HEADER -->
              <div
                v-if="header.value.toLowerCase() === 'last seen' && hasSubRows"
                class="flex flex-row items-center gap-x-1"
              >
                <IcArrowDownRight class="w-4 h-4 text-bb-neutral-gray" />
                <p class="p2 pt-2">Date</p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, rowIndex) in paginatedData">
            <!-- Main Row -->
            <tr
              :key="rowIndex"
              :class="[
                expandedRow === rowIndex ? 'bg-purple-0' : rowIndex % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
                item.sub_rows ? 'cursor-pointer' : '',
              ]"
              @click="item.sub_rows ? toggleRow(rowIndex) : null"
            >
              <td
                v-for="(header, colIndex) in displayedHeaders"
                :key="`main-${rowIndex}-${colIndex}`"
                class="py-5 px-5 border-b border-gray-200 relative"
                :class="{
                  'pl-10': item.sub_rows,
                  'pl-4': !item.sub_rows,
                  'min-w-60': colIndex === 0,
                  'min-w-auto': colIndex !== 0,
                }"
              >
                <IcChevron
                  v-if="item.sub_rows && colIndex === 0"
                  :direction="expandedRow === rowIndex ? 'down' : 'right'"
                  class="absolute left-2 cursor-pointer text-bb-disabled-gray"
                />
                <template v-if="typeof item[header.key] === 'string'">
                  <NewTooltip direction="bottom-start">
                    <template #content><span v-html="lineBreaks(item[header.key])"></span></template>
                    <span
                      :class="{
                        'line-clamp': !isExpanded(rowIndex, colIndex),
                      }"
                      v-html="lineBreaks(item[header.key])"
                    ></span>
                    <p
                      v-if="shouldShowMoreButton(item[header.key])"
                      class="text-bb-brand-purple cursor-pointer"
                      @click="toggleExpand(rowIndex, colIndex)"
                    >
                      {{ isExpanded(rowIndex, colIndex) ? 'Less' : 'More' }}
                    </p>
                  </NewTooltip>
                </template>
                <template v-else>
                  {{ item[header.key] }}
                </template>
              </td>
            </tr>
            <!-- Expanded Sub-Rows -->
            <template v-if="expandedRow === rowIndex && item.sub_rows">
              <tr
                v-for="(sub_row, subIndex) in item.sub_rows"
                :key="`sub-${rowIndex}-${subIndex}`"
                class="bg-white"
              >
                <td
                  v-for="(header, colIndex) in allSubRowHeaders"
                  :key="`sub-${rowIndex}-${subIndex}-${colIndex}`"
                  class="py-3 px-10 max-w-42"
                >
                  <div class="break-words">
                    {{ sub_row[header.key] !== undefined ? sub_row[header.key] : 'N/A' }}
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <TablePagination
      v-if="totalRows > rowsPerPage"
      class="py-3"
      :limit="rowsPerPage"
      :page="currentPage"
      :max="totalRows"
      :disable-next="isNextDisabled"
      :disable-prev="isPrevDisabled"
      @onChange="changePage"
    />
  </div>
</template>

<script>
import TablePagination from '@/components/table/TablePagination'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcArrowDownRight from '@/components/icon/brightbid/ic-arrow-down-right.vue'
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow'
import NewTooltip from '@/components/alert/NewTooltip.vue'

export default {
  name: 'SimpleTable',
  components: {
    TablePagination,
    IcChevron,
    IcArrowDownRight,
    DropdownArrow,
    NewTooltip,
  },
  props: {
    tableData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      rowsPerPage: 10,
      currentPage: 1,
      expandedRow: null,
      sortedColumn: null,
      sortDirection: 'asc',
      expandedCells: {}, // Track expanded state for each cell
    }
  },
  computed: {
    displayedHeaders() {
      const { meta_data } = this.tableData
      if (meta_data && meta_data.main_header) {
        const headers = this.mapHeaders(meta_data.main_header)
        return headers.filter(header => header.key !== 'sub_rows')
      }
      return this.defaultHeaders
    },
    defaultHeaders() {
      const headersSet = new Set()
      this.tableData.data?.forEach(item => Object.keys(item).forEach(key => headersSet.add(key)))
      return Array.from(headersSet)
        .filter(key => key !== 'sub_rows')
        .map(key => ({
          key,
          value: key,
        }))
    },
    allSubRowHeaders() {
      const { meta_data } = this.tableData
      if (meta_data && meta_data.sub_header) {
        const headers = this.mapHeaders(meta_data.sub_header)
        return headers
      }
      return this.defaultSubRowHeaders
    },
    defaultSubRowHeaders() {
      const headersSet = new Set()
      this.tableData.data.forEach(item => {
        if (item.sub_rows) {
          item.sub_rows.forEach(sub_row => {
            Object.keys(sub_row).forEach(key => {
              headersSet.add(key)
            })
          })
        }
      })
      return Array.from(headersSet).map(key => ({
        key,
        value: key,
      }))
    },
    paginatedData() {
      return this.paginateData(this.sortedTableData())
    },
    totalRows() {
      return this.tableData.data?.length || 0
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.rowsPerPage)
    },
    isNextDisabled() {
      return this.currentPage >= this.totalPages
    },
    isPrevDisabled() {
      return this.currentPage <= 1
    },
    hasSubRows() {
      return this.tableData.data.some(item => item.sub_rows && item.sub_rows.length > 0)
    },
  },
  methods: {
    mapHeaders(headers) {
      return Object.keys(headers).map(key => ({
        key,
        value: headers[key],
      }))
    },
    changePage({ current_page }) {
      this.currentPage = current_page
    },
    sortBy(header) {
      this.sortedColumn = header
      this.sortDirection = this.sortedColumn === header && this.sortDirection === 'asc' ? 'desc' : 'asc'
    },
    sortedTableData() {
      const { data, meta_data } = this.tableData
      if (!data || !this.sortedColumn) return data || []
      const isNumeric = meta_data?.numeric_sortable_columns?.includes(this.sortedColumn)
      return this.sortData([...data], isNumeric)
    },
    sortData(data, isNumeric) {
      return data.sort((a, b) => {
        let valA = a[this.sortedColumn]
        let valB = b[this.sortedColumn]
        if (valA === undefined || valB === undefined) return 0
        // Check if the column is numeric
        if (typeof valA === 'number' && typeof valB === 'number') {
          return this.sortDirection === 'asc' ? valA - valB : valB - valA
        }
        // If it's not numeric, use string sorting
        return isNumeric ? this.sortNumeric(valA, valB) : this.sortString(valA, valB)
      })
    },
    sortNumeric(valA, valB) {
      // Ensure values are parsed correctly as numbers
      const numA = typeof valA === 'number' ? valA : parseFloat(valA.replace(/[^\d.-]/g, ''))
      const numB = typeof valB === 'number' ? valB : parseFloat(valB.replace(/[^\d.-]/g, ''))
      if (isNaN(numA) || isNaN(numB)) return 0
      return this.sortDirection === 'asc' ? numA - numB : numB - numA
    },
    sortString(valA, valB) {
      // Ensure both values are strings before applying localeCompare
      valA = valA.toString()
      valB = valB.toString()
      return this.sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA)
    },
    getSortDirection(headerKey) {
      return this.sortedColumn === headerKey ? (this.sortDirection === 'asc' ? 'up' : 'down') : 'right'
    },
    paginateData(data) {
      const start = (this.currentPage - 1) * this.rowsPerPage
      return data.slice(start, start + this.rowsPerPage)
    },
    formatHeader(header) {
      return header
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/[-_]/g, ' ')
        .trim()
    },
    toggleRow(rowIndex) {
      this.expandedRow = this.expandedRow === rowIndex ? null : rowIndex
    },
    lineBreaks(value) {
      return typeof value === 'string' ? value.replace(/\n/g, '<br>') : value
    },
    toggleExpand(rowIndex, colIndex) {
      const key = `${rowIndex}-${colIndex}`
      this.$set(this.expandedCells, key, !this.expandedCells[key])
    },
    isExpanded(rowIndex, colIndex) {
      return this.expandedCells[`${rowIndex}-${colIndex}`]
    },
    shouldShowMoreButton(value) {
      return typeof value === 'string' && value.split('\n').length > 3
    },
  },
}
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
