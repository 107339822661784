<template>
  <div class="shadow-bb-shadow bg-white rounded-lg">
    <div class="py-4 flex flex-col">
      <nav class="flex justify-center sticky top-0 bg-white z-10 py-2">
        <ul class="flex flex-row h5 font-normal">
          <li
            v-for="(item, index) in checksByCategory"
            :key="item.category"
            class="flex items-center"
          >
            <div
              v-if="index !== 0"
              class="border-l border-neutral-100 h-6 mx-4"
            ></div>
            <div
              :class="navItemContent(item.category).background"
              class="flex items-center gap-x-2 py-2 rounded-md cursor-pointer duration-300 ease-linear hover:bg-neutral-0"
              @click.prevent="scrollToSection(item.category)"
            >
              <component
                :is="categoryContent[item.category].icon"
                :class="navItemContent(item.category).component"
              />
              <p
                class="hidden sm:block"
                :class="navItemContent(item.category).link"
              >
                {{ categoryContent[item.category].title }}
              </p>
            </div>
          </li>
        </ul>
      </nav>
      <div class="mt-2 mx-auto">
        <div
          v-for="item in checksByCategory"
          :key="item.category"
          :ref="item.category"
          class="flex flex-col"
        >
          <div class="flex flex-col md:flex-row gap-x-8 px-5 xl:px-10 py-10">
            <div class="md:px-8 bbxl:mt-10">
              <audit-donut
                :percentage="item.score"
                size="large"
              ></audit-donut>
            </div>
            <div class="flex flex-col xl:ml-10 justify-center text-center md:text-left gap-y-5 pt-5">
              <h3 class="pt-5">{{ categoryContent[item.category].title }}</h3>
              <p class="pb-2 break-words text-center px-5 sm:px-0 md:text-left md:max-w-105 xl:max-w-105">
                {{ item.description }}
              </p>
              <div class="flex flex-wrap gap-4 h4 justify-center md:justify-start">
                <filter-button
                  v-for="button in buttons(item.category)"
                  :key="button.buttonType"
                  :is-active="button.isActive"
                  :button-type="button.buttonType"
                  :severity="button.buttonType"
                  class="font-bold hover:shadow-drop-14 hover:transition ease-in-out duration-150 filter-button"
                  @clicked="filterClick(button.buttonType, item.category)"
                >
                  <template #icon>
                    <component
                      :is="severityContent[button.buttonType].icon"
                      :class="severityContent[button.buttonType].textColor"
                      class="w-6 h-6"
                    />
                  </template>
                  {{ button.count }}
                </filter-button>
              </div>
            </div>
          </div>
          <div class="px-5 xl:px-10">
            <p class="h5 py-5">{{ filteredTitle(item.category) }} ({{ filteredNumOfChecks(item.category) }})</p>
            <div
              v-if="showEmptyPageMessage[item.category] && filteredNumOfChecks(item.category) === 0"
              class="flex flex-row gap-x-4 justify-center items-center bg-neutral-0 rounded-lg"
            >
              <img
                :src="emptyPageMessage(item.category).img"
                alt=""
              />
              <div class="flex flex-col py-6">
                <h5 class="font-medium">{{ emptyPageMessage(item.category).title }}</h5>
                <p>{{ emptyPageMessage(item.category).subtitle }}</p>
              </div>
            </div>
            <div
              v-for="check in item.data || []"
              :key="check.id"
              :class="['bg-neutral-0 p-4 border-b border-neutral-100', { 'cursor-pointer': check.status !== 'failed' }]"
              @click.stop="check.status !== 'failed' && openModal(check)"
            >
              <div class="flex flex-row justify-between items-center">
                <div class="flex flex-row gap-x-5 items-center">
                  <div
                    v-if="check.status !== 'failed'"
                    class="p-1"
                  >
                    <component
                      :is="severityContent[check.status === 'skipped' ? check.status : check.severity].icon"
                      :class="severityContent[check.status === 'skipped' ? check.status : check.severity].headerColor"
                      class="w-6 h-6"
                    />
                  </div>
                  <div
                    v-if="check.status === 'failed'"
                    class="p-2 rounded-full flex items-center justify-center bg-none"
                  >
                    <ic-alert
                      v-if="check.status_reason === 'no_brand_terms'"
                      class="p-1 text-neutral-100"
                    ></ic-alert>
                    <IcAlertRhombus
                      v-else
                      class="w-6 h-6 text-bb-disabled-gray"
                    />
                  </div>
                  <p
                    v-if="check.status === 'failed'"
                    class="font-medium text-bb-disabled-gray pr-3"
                  >
                    {{ check.title }}
                  </p>
                  <p
                    v-if="check.status !== 'failed'"
                    class="font-medium pr-3"
                  >
                    {{ check.title }}
                  </p>
                </div>
                <div class="flex flex-row items-center gap-x-4">
                  <p
                    v-if="check.status !== 'failed' && check.issues_count > 0"
                    class="h5 font-bold rounded-full w-6 h-6 flex items-center justify-center"
                    :class="severityContent[check.severity].headerColor"
                  >
                    {{ check.issues_count }}
                  </p>
                  <merge-button-round
                    v-if="readonly || check.status !== 'failed'"
                    button-type="tertiary"
                    class="hidden sm:block"
                  >
                    See more
                  </merge-button-round>
                  <div
                    v-if="check.status_reason === 'no_brand_terms'"
                    class="text-bb-brand-purple flex flex-row gap-2 items-center"
                    @click.stop="$router.push({ name: 'site:settings:site', query: { tab: 'SiteAndBusinessForm' } })"
                  >
                    <ic-cog :size="16" />
                    <p>Add brand terms to enable</p>
                  </div>
                  <merge-button-round
                    v-if="check.status === 'failed'"
                    button-type="disabled"
                    class="text-bb-error"
                  >
                    <div class="flex flex-row items-center gap-2">
                      Invalid

                      <new-tooltip direction="top">
                        <IcAlertCircle :size="16" />
                        <template #content>An error occurred while running the check.</template>
                      </new-tooltip>
                    </div></merge-button-round
                  >
                  <ic-chevron-right
                    v-if="check.status !== 'failed'"
                    class="text-bb-disabled-gray sm:hidden block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcKeyOutline from 'vue-material-design-icons/KeyOutline'
import IcMovieOutline from 'vue-material-design-icons/MovieOutline'
import IcStar from '@/components/icon/brightbid/ic-star'
import IcBullHorn from '@/components/icon/brightbid/ic-campaign.vue'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import AuditDonut from '@/components/audit-components/AuditDonut.vue'
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcAlertTriangle from 'vue-material-design-icons/AlertOutline'
import IcLightbulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import FilterButton from '@/components/audit-components/FilterButton.vue'
import IcAlertRhombus from 'vue-material-design-icons/AlertRhombusOutline'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import IcMinusCircleOutline from 'vue-material-design-icons/MinusCircleOutline'
import IcCog from 'vue-material-design-icons/Cog'
import IcAlert from 'vue-material-design-icons/Alert'

export default {
  components: {
    IcKeyOutline,
    IcMovieOutline,
    IcStar,
    IcBullHorn,
    IcChevronRight,
    AuditDonut,
    IcAlertCircle,
    IcAlertTriangle,
    IcLightbulbOutline,
    IcCheckCircleOutline,
    FilterButton,
    IcAlertRhombus,
    NewTooltip,
    IcMinusCircleOutline,
    IcCog,
    IcAlert,
  },
  props: {
    checks: {
      type: Array,
      required: false,
      default: () => [],
    },
    categoryScores: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedCategory: null,
      selectedSeverity: {},
      showEmptyPageMessage: {},
      observer: null,
    }
  },
  computed: {
    checksByCategory() {
      const desiredOrder = ['account', 'campaign', 'ads', 'keywords']

      // Group checks by category
      const groupedChecks = this.checks.reduce((acc, check) => {
        if (!acc[check.category]) {
          acc[check.category] = []
        }
        acc[check.category].push(check)
        return acc
      }, {})

      // Define severity order
      const severityOrder = ['urgent', 'warning', 'optimization', 'passed', 'skipped']

      // Create the result array and sort based on desired order
      return desiredOrder.map(category => {
        let filteredChecks = groupedChecks[category] || []
        const selectedSeverityForCategory = this.selectedSeverity[category] || ''

        // Apply filtering based on selected severity
        if (selectedSeverityForCategory) {
          filteredChecks = filteredChecks.filter(
            check => check.severity === selectedSeverityForCategory && check.status !== 'skipped',
          )
        }

        const score = this.categoryScores[category] ? this.categoryScores[category].score * 0.01 : 0

        // Sort the checks by severity and then alphabetically by title
        filteredChecks.sort((a, b) => {
          const severityComparison =
            severityOrder.indexOf(a.status === 'skipped' ? 'skipped' : a.severity) -
            severityOrder.indexOf(b.status === 'skipped' ? 'skipped' : b.severity)

          if (severityComparison !== 0) return severityComparison

          return a.title.localeCompare(b.title) // Sort alphabetically if severity is the same
        })

        return {
          category,
          score,
          description: this.getCategoryDescription(score, category),
          data: filteredChecks,
        }
      })
    },

    emptyPageMessage() {
      return category => {
        const selectedSeverity = this.selectedSeverity[category] || ''
        if (!selectedSeverity) return { title: '', subtitle: '', img: '' }

        const messages = {
          passed: {
            title: 'You haven’t passed any checks.',
            subtitle: 'Please take action on the suggested recommendations.',
            img: '/content/images/brightbid/robot-red-warning.svg',
          },
          optimization: {
            title: 'All Set!',
            subtitle: 'Your account is optimized.',
            img: '/content/images/brightbid/robot-bulb.svg',
          },
          warning: {
            title: 'Great!',
            subtitle: 'No warnings to display.',
            img: '/content/images/brightbid/robot-warning.svg',
          },
          urgent: {
            title: 'Great!',
            subtitle: 'No urgent issues to display.',
            img: '/content/images/brightbid/robot-urgent.svg',
          },
        }

        return messages[selectedSeverity] || { title: '', subtitle: '', img: '' }
      }
    },

    filteredTitle() {
      return category => {
        const selectedSeverityForCategory = this.selectedSeverity[category] || ''
        if (selectedSeverityForCategory === '') {
          return 'All checks'
        }

        const button = this.buttons(category).find(button => button.buttonType === selectedSeverityForCategory)
        return {
          passed: 'Passed Checks',
          optimization: 'Optimizations',
          warning: 'Warnings',
          urgent: 'Urgent Issues',
        }[button ? button.buttonType : '']
      }
    },
    filteredNumOfChecks() {
      return category => {
        const selectedSeverityForCategory = this.selectedSeverity[category] || ''
        if (selectedSeverityForCategory === '') {
          return this.checks.filter(check => check.category === category).length
        }

        const button = this.buttons(category).find(button => button.buttonType === selectedSeverityForCategory)
        return button ? button.count : 0
      }
    },

    categoryContent() {
      return {
        campaign: {
          title: 'Campaign',
          icon: 'ic-bull-horn',
        },
        account: {
          title: 'Account',
          icon: 'ic-star',
        },
        ads: {
          title: 'Ads & Assets',
          icon: 'ic-movie-outline',
        },
        keywords: {
          title: 'Keywords',
          icon: 'ic-key-outline',
        },
      }
    },
    severityContent() {
      return {
        urgent: {
          icon: 'IcAlertTriangle',
          textColor: 'text-bb-error',
          headerColor: 'text-bb-error bg-bb-red-50',
        },
        warning: {
          icon: 'IcAlertCircle',
          textColor: 'text-bb-warning',
          headerColor: 'text-bb-warning bg-bb-yellow-50',
        },
        optimization: {
          icon: 'IcLightbulbOutline',
          bgColor: 'bg-bb-secondary-purple',
          textColor: 'text-bb-brand-purple',
          headerColor: 'text-bb-brand-purple bg-bb-secondary-purple',
        },
        passed: {
          icon: 'IcCheckCircleOutline',
          textColor: 'text-success',
          headerColor: 'text-success bg-bb-success-bg',
        },
        skipped: {
          icon: 'ic-minus-circle-outline',
          textColor: 'text-bb-disabled-buttons',
          headerColor: 'text-bb-disabled-buttons bg-bb-success-bg',
        },
      }
    },
    buttons() {
      return category => {
        const severityCounts = this.checks
          .filter(check => check.category === category && check.status !== 'skipped')
          .reduce((acc, check) => {
            if (!acc[check.severity]) {
              acc[check.severity] = 0
            }
            acc[check.severity]++
            return acc
          }, {})

        return [
          {
            isActive: this.selectedSeverity[category] === 'passed',
            buttonType: 'passed',
            count: severityCounts.passed || 0,
          },
          {
            isActive: this.selectedSeverity[category] === 'optimization',
            buttonType: 'optimization',
            count: severityCounts.optimization || 0,
          },
          {
            isActive: this.selectedSeverity[category] === 'warning',
            buttonType: 'warning',
            count: severityCounts.warning || 0,
          },
          {
            isActive: this.selectedSeverity[category] === 'urgent',
            buttonType: 'urgent',
            count: severityCounts.urgent || 0,
          },
        ]
      }
    },
    navItemContent() {
      return category => {
        const isSelected = this.selectedCategory === category
        return {
          component: isSelected ? 'text-bb-brand-purple' : 'text-bb-disabled-buttons',
          link: isSelected ? 'text-bb-brand-purple font-medium' : 'text-bb-text-default',
          background: isSelected ? 'bg-neutral-0 rounded-lg py-2 px-4' : 'bg-white rounded-lg py-2 px-4',
        }
      }
    },
  },
  mounted() {
    if (this.checksByCategory.length > 0) {
      this.selectedCategory = this.checksByCategory[0].category
    }

    this.setupScrollSpy()
  },
  methods: {
    scrollToSection(category) {
      this.selectedCategory = category
      const section = this.$refs[category][0]
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' })
      }
    },
    scrollToSeverity(category) {
      this.$nextTick(() => {
        const element = document.getElementById(category)
        if (!element) return
        element.scrollIntoView({ behavior: 'smooth' })
      })
    },
    openModal(checkId) {
      this.$emit('openModal', checkId)
    },
    filterClick(type, category) {
      this.$set(this.showEmptyPageMessage, category, false)
      const button = this.buttons(category).find(button => button.buttonType === type)

      if (button && button.count === 0) {
        this.$set(this.showEmptyPageMessage, category, true)
      }

      if (this.selectedSeverity[category] === type) {
        this.$set(this.selectedSeverity, category, '')
      } else {
        this.$set(this.selectedSeverity, category, type)
      }
    },
    setupScrollSpy() {
      const options = {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.25, // Adjust this value as needed
      }

      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.selectedCategory = entry.target.getAttribute('data-category')
          }
        })
      }, options)

      this.checksByCategory.forEach(item => {
        const section = this.$refs[item.category][0]
        if (section) {
          section.setAttribute('data-category', item.category)
          this.observer.observe(section)
        }
      })
    },
    beforeDestroy() {
      if (this.observer) {
        this.observer.disconnect()
      }
    },
    getCategoryDescription(score, category) {
      let description = ''
      switch (category.toLowerCase()) {
        case 'account':
          if (score >= 0.95) {
            description = `Your account is in great shape, you are all set up for success! Having a good account setup is the base of being able to perform well. This includes having tracking and billing setup while meeting all requirements not to be suspended.`
          } else if (score >= 0.8) {
            description = `We found some issues on your account, since account level issues tend to affect all campaigns performance. We suggest you fix all issues listed below. Having a good account setup is the base of being able to perform well. This includes having tracking and billing setup while meeting all requirements not to be suspended.`
          } else {
            description = `Your account has some urgent issues that need your attention. We recommend you fix these issues as soon as possible since account level issues tend to affect all campaigns performance. All issues found are listed below. Having a good account setup is the base of being able to perform well. This includes having tracking and billing setup while meeting all requirements not to be suspended.`
          }
          break
        case 'campaign':
          if (score >= 0.8) {
            description = `Your campaigns are well optimised and follow most of the best practices. To improve your performance, make sure you check out the suggestions below. Following the best practices when setting up campaigns is a must-have to keep control of your spend and basics to start optimising.`
          } else if (score >= 0.5) {
            description = `Your campaigns have some issues that need your attention. We suggest you fix the issues found in the list below to start optimising your campaigns for better performance. Following the best practices when setting up campaigns is a must-have to keep control of your spend and basics to start optimising.`
          } else {
            description = `Your campaigns have some urgent issues that need your attention. We suggest you prioritise urgent issues and warnings found below before you move on to optimise your campaigns further. Following the best practices when setting up campaigns is a must-have to keep control of your spend and basics to start optimising.`
          }
          break
        case 'ads':
          if (score >= 0.8) {
            description = `Your ads are well optimised and you have most assets and extensions in place to perform well. To improve your performance, make sure you check out the suggestions below. Having high quality and relevant assets in your ad groups is how you beat the competition and pay less for valuable traffic.`
          } else if (score >= 0.5) {
            description = `Some of your ads have potential to perform better if you improve the relevance and quality. We suggest you check out the suggestions below to optimise your assets. Having high quality and relevant assets in your ad groups is how you beat the competition and pay less for valuable traffic.`
          } else {
            description = `Your ads have some urgent issues that need your attention. We suggest you prioritise urgent issues and warnings found below before you move on to optimise your assets further. Having high quality and relevant assets in your ad groups is how you beat the competition and pay less for valuable traffic.`
          }
          break
        case 'keywords':
          if (score >= 0.8) {
            description = `Your setup for targeting keywords is well optimised. To improve your performance, make sure you check out the suggestions below. Targeting high quality and relevant keywords with correct match types in your ad groups is how you fine tune your performance.`
          } else if (score >= 0.5) {
            description = `Some of your keywords have potential to perform better if you improve their relevance and quality. We suggest you check out the suggestions below to optimise your keyword targeting. Targeting high quality and relevant keywords with correct match types in your ad groups is how you fine tune your performance.`
          } else {
            description = `Your keywords have some urgent issues that need your attention. We suggest you prioritise urgent issues and warnings found below before you move on to optimise your keyword targeting further. Targeting high quality and relevant keywords with correct match types in your ad groups is how you fine tune your performance.`
          }
          break
        default:
          description = `No description available for category: ${category}`
      }

      return description
    },
  },
}
</script>
<style scoped>
.filter-button {
  transition: transform 0.3s ease;
}

.filter-button:hover {
  transform: scale(1.03);
}
</style>
